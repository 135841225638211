import React, {lazy} from 'react';
import {Outlet, RouteObject} from 'react-router-dom';
import NotificationProvider from '@local/frontend/providers/NotificationProvider';
import ModalProvider from '@local/frontend/libs/modals/ModalProvider';

const MultiCardPageRoute = lazy(
  () =>
    import(/* webpackChunkName: "multi-card-page" */ './MultiCardPage.Route')
);

export const multiCardRoutes: RouteObject = {
  path: 'm',
  element: (
    <NotificationProvider>
      <ModalProvider>
        <Outlet />
      </ModalProvider>
    </NotificationProvider>
  ),
  children: [
    {
      path: ':multiCardId',
      element: <MultiCardPageRoute />,
    },
  ],
};
