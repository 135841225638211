import Stack from '@mui/material/Stack';
import React, {FC} from 'react';
import {Money, MoneyUtils} from '@handsin/money';
import Typography from '@mui/material/Typography';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {Trans, useTranslation} from 'react-i18next';
import useCachedGroupPayment from '@local/frontend/hooks/useCachedGroupPayment';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import Lottie from 'lottie-react';
import ShareSection from '@local/frontend/sections/groupCheckout/ShareSection';
import Button from '@mui/material/Button';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import useCustomer from '@local/frontend/hooks/data/customers';
import {formatFullName} from '@local/frontend/util/stringFormatters';
import ModalHeader from '../ModalHeader';
import successAnimationData from './SuccessAnimation.json';

interface PaymentApprovedModalProps {
  approvedMoney: Money;
  onBehalfOf?: string;
}

const PaymentApprovedModal: FC<
  React.PropsWithChildren<PaymentApprovedModalProps>
> = ({approvedMoney, onBehalfOf}) => {
  const {t} = useTranslation([
    'payment-approved',
    'clipboard-invite',
    'common',
  ]);
  const groupPayment = useCachedGroupPayment();
  const {closeModal} = useCustomModals();
  const onBehalfOfCustomer = useCustomer(onBehalfOf);

  return (
    <Stack spacing={2} useFlexGap>
      <ModalHeader
        modalName={ModalName.PAYMENT_APPROVED}
        title={t('title', {ns: 'payment-approved'})}
        titleTypographyProps={{sx: {color: 'primary.dark'}}}
        subtitleTypographyProps={{
          variant: 'subtitle1',
          color: 'primary',
          align: 'center',
        }}
        subtitle={
          <Trans
            ns="payment-approved"
            i18nKey={onBehalfOf ? 'description_onBehalfOf' : 'description'}
            values={{
              approvedMoney: MoneyUtils.formatMoney(approvedMoney),
              customerName: onBehalfOfCustomer
                ? formatFullName(
                    onBehalfOfCustomer.firstName,
                    onBehalfOfCustomer.lastName
                  )
                : t('descriptionNoCustomer', {ns: 'payment-approved'}),
            }}
            components={{
              span: (
                <Typography
                  variant="subtitle1"
                  sx={{fontWeight: 800, fontSize: 22}}
                  component="span"
                />
              ),
            }}
          />
        }
      />
      <Stack alignItems="center" justifyContent="center">
        <Lottie
          animationData={successAnimationData}
          loop={false}
          style={{width: 250}}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h6">
          {t('nextCard.title', {ns: 'payment-approved'})}
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={1}>
          <NotificationsActiveOutlinedIcon />
          <Typography variant="body2">
            {t('nextCard.notificationDescription', {ns: 'payment-approved'})}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" spacing={1}>
          <EmailOutlinedIcon />
          <Typography variant="body2">
            {t('nextCard.emailDescription', {ns: 'payment-approved'})}
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <ShareSection groupPayment={groupPayment} />
      </Stack>
      <Button
        variant="contained"
        sx={{backgroundColor: '#2cda94'}}
        fullWidth
        onClick={() => closeModal(ModalName.PAYMENT_APPROVED)}
      >
        {t('button.continue', {ns: 'common'})}
      </Button>
    </Stack>
  );
};

export default PaymentApprovedModal;
