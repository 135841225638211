import React, {FC, useEffect} from 'react';
import {usePostHog} from 'posthog-js/react';
import {useSearchParams} from 'react-router-dom';
import useCustomerId from '../hooks/useCustomerId';
import {useInitializeCustomer} from '../hooks/queries/customers';

const PostHogIdentifyWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [searchParams] = useSearchParams();
  const merchantId = searchParams.get('mid');
  const customerId = useCustomerId();
  const {data: customer} = useInitializeCustomer(customerId);
  const posthog = usePostHog();

  useEffect(() => {
    if (posthog && customer) {
      posthog.identify(customer.id, {
        merchantId,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phoneNumber: customer.phoneNumber,
        language: customer.language,
        country: customer.address?.country ?? null,
        createdAt: customer.createdAt,
      });
    }
  }, [posthog, customer]);

  return <>{children}</>;
};

export default PostHogIdentifyWrapper;
