import type {DefaultResponseError} from '@local/backend/@types/updated-api-types/DefaultResponseError';
import {useEffect} from 'react';
import {z} from 'zod';
import config from '../config';

export enum IframeEvent {
  // Event fired once the iframe has loaded
  LOADED = 'LOADED',

  // triggered on any payment errors
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  // triggered if a payment failed
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  // triggered when a payment is authorized
  PAYMENT_APPROVED = 'PAYMENT_APPROVED',
  // triggered when a payment is being processed
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  // triggered when a payment is captured
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  // triggered once an authorized payment expires
  PAYMENT_EXPIRED = 'PAYMENT_EXPIRED',
  // triggered when a payment is voided
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',

  // triggered on any multi-card errors
  MULTI_CARD_ERROR = 'MULTI_CARD_ERROR',
  // triggered when a multi-card session expires
  MULTI_CARD_EXPIRED = 'MULTI_CARD_EXPIRED',
  // triggered when a multi-card session is cancelled
  MULTI_CARD_CANCELLED = 'MULTI_CARD_CANCELLED',
  // triggered when the total money for a multi-card payment has been authorized
  MULTI_CARD_APPROVED = 'MULTI_CARD_APPROVED',
  // triggered when the total money for a multi-card payment has been captured
  MULTI_CARD_COMPLETED = 'MULTI_CARD_COMPLETED',

  // triggered on any group payment errors
  GROUP_PAYMENT_ERROR = 'GROUP_PAYMENT_ERROR',
  // triggered when a group payment session expires
  GROUP_PAYMENT_EXPIRED = 'GROUP_PAYMENT_EXPIRED',
  // triggered when a group payment session is cancelled
  GROUP_PAYMENT_CANCELLED = 'GROUP_PAYMENT_CANCELLED',
  // triggered when the total money for a group payment has been authorized
  GROUP_PAYMENT_APPROVED = 'GROUP_PAYMENT_APPROVED',
  // triggered when the total money for a group payment has been captured
  GROUP_PAYMENT_COMPLETED = 'GROUP_PAYMENT_COMPLETED',
}

export type IframeMessage =
  | {
      event: IframeEvent.LOADED;
    }
  | {
      event: IframeEvent.PAYMENT_ERROR;
      error: DefaultResponseError;
    }
  | {
      event:
        | IframeEvent.PAYMENT_FAILED
        | IframeEvent.PAYMENT_APPROVED
        | IframeEvent.PAYMENT_PENDING
        | IframeEvent.PAYMENT_COMPLETED
        | IframeEvent.PAYMENT_EXPIRED
        | IframeEvent.PAYMENT_CANCELLED;
      data: {
        id: string; // payment identifier
        merchantId: string;
      };
    }
  | {
      event: IframeEvent.MULTI_CARD_ERROR;
      error: DefaultResponseError;
    }
  | {
      event:
        | IframeEvent.MULTI_CARD_APPROVED
        | IframeEvent.MULTI_CARD_COMPLETED
        | IframeEvent.MULTI_CARD_EXPIRED
        | IframeEvent.MULTI_CARD_CANCELLED;
      data: {
        id: string; // multi-card identifier
        merchantId: string;
      };
    }
  | {
      event: IframeEvent.GROUP_PAYMENT_ERROR;
      error: DefaultResponseError;
    }
  | {
      event:
        | IframeEvent.GROUP_PAYMENT_APPROVED
        | IframeEvent.GROUP_PAYMENT_COMPLETED
        | IframeEvent.GROUP_PAYMENT_EXPIRED
        | IframeEvent.GROUP_PAYMENT_CANCELLED;
      data: {
        id: string; // group payment identifier
        merchantId: string;
      };
    };

export const iframeMessageSchema = z.object({
  source: z.literal('handsin'),
  event: z.nativeEnum(IframeEvent),
  data: z.object({id: z.string(), merchantId: z.string()}).optional(),
  error: z
    .object({
      http_status_code: z.number(),
      moreInfo: z.string().optional(),
      name: z.string(),
      detail: z.string().optional(),
      instance: z.string().optional(),
    })
    .optional(),
});

export const sendIframeMessage = (message: IframeMessage): void => {
  window.parent.postMessage({source: 'handsin', ...message}, '*');
};

export const useIframeMessageListener = (
  onEvent?: (message: IframeMessage) => void
): void => {
  useEffect(() => {
    const listener = (event: WindowEventMap['message']) => {
      if (
        config.ENV === 'development' ||
        config.ALLOWED_HOSTS.includes(event.origin)
      ) {
        if (event.data) {
          const parsedData = iframeMessageSchema.safeParse(event.data);
          if (parsedData.success) {
            onEvent?.(parsedData.data as IframeMessage);
          }
        }
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [onEvent]);
};
